import React from 'react';
import styled, { keyframes } from 'styled-components';

const LetterWrapper = (props) => (
    <StyledParagraph delay={props.delay}>
        {props.letter}
        {props.children}
    </StyledParagraph>
);

const titleLetterAnimation = keyframes`
  0% {
    transform: translate3d(-1500px, 0, 0);
    opacity:0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
`;
const titleLetterDissapear = keyframes`
  0% {
    opacity:0;
  }

  99% {
    opacity:0;
  }
  100% {
    opacity: 1;
  }
}
`;

const StyledParagraph = styled.span`
    animation: ${titleLetterDissapear} 1.7s, ${props => props.delay}s ${titleLetterAnimation} 1.5s;
`;

export default LetterWrapper;