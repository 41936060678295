import React from 'react';
import PropTypes from 'prop-types';
import {AppContainer} from 'react-hot-loader';

import {Router, Route, IndexRedirect} from 'react-router';
import {Provider} from 'react-redux';

// Redux store
import store from './store';

// Layouts
import App from './App';
import Home from './views/home';
import Intro from './views/intro';
import Cover from './views/cover';
import How from './views/how';
import Where from './views/where';
import Why from './views/why';
import What from './views/what';
import ClientProjects from './views/clientProjects';
import ProprietaryProjects from './views/proprietaryProjects';
import LetterDescriptionPage from './views/letter';
import ProjectDetails from './views/projectDetails';
import Contact from './views/contact';
import InfoPage from './views/infoPage';
import Join from './views/join';

if (process.env.REACT_HOT) {
    var {hot} = require('react-hot-loader/root');
}

Router.prototype.componentWillReceiveProps = function() {
    // disable warnings due to hot reload
};

/*** Routes are NOT hot reloaded ***/
class Root extends React.Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
    }
    render() {
        return <AppContainer>
            <Provider store={store}>
                <Router history={this.props.history}>
                    <Route path="/" component={App}>
                        <IndexRedirect to="intro/" />
                        <Route path="home" component={Home}/> 
                        <Route path="virtual-reality-innovation-projects/" component={LetterDescriptionPage}/>
                        <Route path="design-branding-projects/" component={LetterDescriptionPage}/>
                        <Route path="engagement-social-media-projects/" component={LetterDescriptionPage}/>
                        <Route path="SEO-CRO-UX-optimisation-projects/" component={LetterDescriptionPage}/>
                        <Route path="loyalty-programs-projects/" component={LetterDescriptionPage}/>
                        <Route path="mobile-apps-omnichannel-ecommerce-projects/" component={LetterDescriptionPage}/>
                        <Route path="mobile-games-VR-games-gamification-projects/" component={LetterDescriptionPage}/>
                        <Route path="info/:info" component={InfoPage}/>
                        <Route path="project/:project/:index" component={ProjectDetails}/>
                        <Route path="areas-of-expertise/" component={How}/>
                        <Route path="about/" component={Why}/>
                        <Route path="portfolio/" component={What}/>
                        <Route path="where/:location/" component={Where}/>
                        <Route path="clients/" component={ClientProjects}/>
                        <Route path="proprietary-solutions/" component={ProprietaryProjects}/>
                    </Route>
                        <Route path="cover" component={Cover} />
                        <Route path='contact/' component={Contact} />
                        <Route path='join' component={Join} />
                        <Route path="intro/" component={Intro} />
                </Router>
            </Provider>
        </AppContainer>;
    }
}

export default process.env.REACT_HOT ? hot(Root) : Root;