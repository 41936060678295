import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import './style.scss';
import { Container, Row } from 'react-bootstrap';
import Map from '../../components/map';
import Footer from '../../components/footer';
import xSignSmall from '../../assets/logos/xSignSmallw.svg';
import LetterWrapper from '../home/letterWrapper';

const isMobile = window.innerWidth <= 500;

class Where extends Component {
  
  state = {
    visibleCard: null,
    cards: {
      ro: {
        title: 'IDEOLOGIQ RO',
        adress: '21 Sperantei St 020993, District 2 Bucharest, Romania',
        phone: '0724565356',
        email: 'office@ideologiq.com',
      },
      gr: {
        title: 'Ideologiq Aegean Μονοπρόσωπη Ι.Κ.Ε.',
        adress: 'Αριθμός Γ.Ε.ΜΗ.: 166757306000, ΑΦΜ: 801944140 - ΔΟΥ: Α Θεσσαλονίκης, Φρίξου 15, 54627 Θεσσαλονίκη',
        phone: '+30 69700 803 738',
        email: 'office@ideologiq.com',
      }
    }
  };
  
  componentDidMount () {
    const { location } = this.props.params;
    let visibleCard = isMobile ? null : location === 'romania-bucharest-head-office' ? 'ro' : 'gr';
    this.setState({ visibleCard });
  }
  
  render () {
    const { location } = this.props.params;
    console.log('Location: ', location);
    const { visibleCard, cards } = this.state;
    console.log('VIZIVIL', visibleCard);
    const card = visibleCard === 'ro' ? cards['ro'] : visibleCard === 'gr' ? cards['gr'] : null;
    return (
      <Container fluid className="Where" ref={this.myRef}>
        <div className="Where--overlay">
          {card && <div className="Where--card"
                        onClick={() => {
                          if (!isMobile) {
                            browserHistory.push('/contact/');
                          }
                        }}
                        style={{
                          marginLeft: isMobile || visibleCard === 'ro' ? 0 : '100%',
                          backgroundColor: visibleCard === 'ro' ? 'orange' : '#231F20',
                        }}>
            <div className="Where--card--content">
              {isMobile && <img src={xSignSmall} className="Where--card--close" onClick={() => {this.setState({ visibleCard: null });}}/>}
              <p className="Where--card--title">
                {card.title}
              </p>
              <p className="Where--card--address" style={{ color: visibleCard !== 'ro' ? 'white' : 'black' }}>
                {card.adress}
              </p>
              <p className="Where--card--address" style={{ color: visibleCard !== 'ro' ? 'white' : 'black' }}>
                {card.email}
              </p>
              <p className="Where--card--address" style={{ color: visibleCard !== 'ro' ? 'white' : 'black' }}>
                {card.phone}
              </p>
              <p className="Where--card--contact" style={{ color: visibleCard !== 'ro' ? 'orange' : 'white' }} onClick={() => {browserHistory.push('/contact/');}}>
                Contact us
              </p>
            </div>
          </div>}
          <span className="Where--overlay--title--letters">
            <LetterWrapper className="Why--title--letter" letter="W" delay={1.3}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter="H" delay={1.1}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter="E" delay={0.9}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter="R" delay={0.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter="E" delay={0.5}></LetterWrapper>
          </span>
          <div className="Where--overlay--locations">
            <p onClick={() => {
              browserHistory.push('/where/romania-bucharest-head-office/');
              this.setState({ visibleCard: 'ro' });
            }}>
              Ideologiq Head Office
            </p>
            <p onClick={() => {
              browserHistory.push('/where/greece-athens-office/');
              this.setState({ visibleCard: 'gr' });
            }}>
              Ideologiq Greece
            </p>
          </div>
        </div>
        <div className="Where--mapContainer">
          <div className="Where--map">
            <Map
              ref={(ref) => {
                this.mapRef = ref;
              }}
              markerClick={() => browserHistory.push('/contact/')}
              loc={location}
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBOvNRan2m9nfAGuMSuZ3SyjvyztJUW_DU"
              loadingElement={<div style={{ height: `100%` }}/>}
              containerElement={<div style={{ height: `100%` }}/>}
              mapElement={<div style={{ height: `100%` }}/>}
            />
          </div>
        </div>
        
        <Row className="Where--footer">
          <Footer onClick={() => this.handleScroll()}/>
        </Row>
      </Container>
    );
  }
}

export default Where;
