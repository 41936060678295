import React from 'react';
import styled, { keyframes } from 'styled-components';

const ImportedComponent = (props) => (
    <StyledSVG viewBox="0 0 717 646" color={props.color}>
        <circle className="a1" cx="73" cy="73" r="73"/>
        <circle className="a2" cx="153.5" cy="153.5" r="153.5"/>
        <circle className="a3" cx="28" cy="28" r="28"/>
        <circle className="a4" cx="39" cy="39" r="39" transform="translate(48 0)"/>
        <circle className="d" cx="323" cy="323" r="323" transform="translate(71 0)"/>
        <circle className="e" cx="130" cy="130" r="130" transform="translate(161 123)"/>
    </StyledSVG>
);

const drivea1 = keyframes`
  0% {
    transform:translate(250px, 250px) scale(0.1);
  }
  2% {
    transform:translate(101px, 57px) scale(1.5);
  }
  4% {
    transform:translate(101px, 57px) scale(1.0);
  }
  100% {
    transform:translate(101px, 57px) scale(1.0);
  }
}
`;
const drivea2 = keyframes`
  0% {
    transform:translate(153.5px, 355.5px) scale(0.1);
  }
  2% {
    transform:translate(153.5px, 355.5px) scale(1.3);
  }
  4% {
    transform:translate(153.5px, 355.5px) scale(1.0);
  }
  100% {
    transform:translate(153.5px, 355.5px) scale(1.0);
  }
}
`;
const drivea3 = keyframes`
  0% {
    transform:translate(542px, 28px) scale(0.1);
  }
  2% {
    transform:translate(542px, 28px) scale(1.5);
  }
  4% {
    transform:translate(542px, 28px) scale(1.0);
  }
  100% {
    transform:translate(542px, 28px) scale(1.0);
  }
}
`;
const drivea4 = keyframes`
  0% {
    transform:translate(200px, 200px) scale(0.1);
  }
  2% {
    transform:translate(400px, 250px) scale(1.5);
  }
  4% {
    transform:translate(400px, 250px) scale(1.0);
  }
  100% {
    transform:translate(400px, 250px) scale(1.0);
  }
}
`;
const drawItself = keyframes`
4% {
  stroke-dashoffset: 0;
}
100% {
  stroke-dashoffset: 0;
}
`;

const StyledSVG = styled.svg`
margin-left: -150px;
margin-top: -160px;
width: 450px;
position: absolute;
z-index: -1;
height: 600px; 
  & .e {
    animation: ${drawItself} 20s;
    animation-delay: 0.3s;
    stroke-dasharray: 900;
    stroke-dashoffset: 900;
    fill: transparent;
    stroke: ${props => props.color};
  }
  & .d {
    animation: ${drawItself} 20s;
    stroke: ${props => props.color};
    stroke-dasharray: 2100;
    fill: transparent;
    stroke-dashoffset: 2100;
  }
  & .a1 {
    animation: ${drivea1} 20s;
    fill: ${props => props.color};
    transform: scale(0);
    animation-delay: 0.6s;
    opacity: 1;

  }
  & .a2 {
    animation: ${drivea2} 20s;
    fill: ${props => props.color};
    animation-delay: 0.5s;
    transform: scale(0);
    opacity: 0.46;
  }
  & .a3 {
    animation: ${drivea3} 20s;
    fill: ${props => props.color};
    animation-delay: 0.6s;
    transform: scale(0);
    opacity: 0.46;

  }
  & .a4 {
    animation: ${drivea4} 20s;
    fill: ${props => props.color};
    animation-delay: 0.6s;
    transform: scale(0);
    opacity: 0.46;
  }
`;

export default ImportedComponent;