import React, { Component } from 'react'
import './style.scss';
import {browserHistory} from 'react-router';
import arrowLeft from '../../assets/logos/arrowLeft.svg';
import lightArr from '../../assets/logos/lightArr.svg';
import styled, { keyframes } from 'styled-components';

const isMobile = window.innerWidth <= 500;

const divTransition = keyframes`
  0% {
    transform: scale();
  }
  100% {
    transform: scale(500);
  }
`

const Circle = styled.div`
  width: 40px;
  border-radius: 50%;
  background-color: ${props => props.color};
  height: 40px;
  animation: ${divTransition} 2s;
`
const CircleWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 50%;
  margin-top:50%;
  transform: translate(10%, 5%);
`

class LetterNavigation extends Component {
  
  state = {
    visible: null,
    changePage: false,
    color: 'white',
    letters: [
      {
        letter: 'I',
        link: '/virtual-reality-innovation-projects/',
        color: '#FE4443',
        small: 'i',
        next: '/design-branding-projects/',
        prev: '/mobile-games-VR-games-gamification-projects/',
      },
      {
        letter: 'D',
        link: '/design-branding-projects/',
        color: '#FFB000',
        small: 'd',
        next: '/engagement-social-media-projects/',
        prev: '/virtual-reality-innovation-projects/',
      },{
        letter: 'E',
        link: '/engagement-social-media-projects/',
        color: '#8ABB2A',
        small: 'e',
        next: '/SEO-CRO-UX-optimisation-projects/',
        prev: '/design-branding-projects/',
      },{
        letter: 'O',
        link: '/SEO-CRO-UX-optimisation-projects/',
        color: '#CC004A',
        small: 'o',
        next: '/loyalty-programs-projects/',
        prev: '/engagement-social-media-projects/',
      },{
        letter: 'L',
        link: '/loyalty-programs-projects/',
        color: '#00A1ED',
        small: 'l',
        next: '/mobile-apps-omnichannel-ecommerce-projects/',
        prev: '/SEO-CRO-UX-optimisation-projects/',
      },{
        letter: 'O',
        link: '/mobile-apps-omnichannel-ecommerce-projects/',
        color: '#03D4FF',
        small: 'o2',
        next: '/mobile-games-VR-games-gamification-projects/',
        prev: '/loyalty-programs-projects/',
      },{
        letter: 'G',
        link: '/mobile-games-VR-games-gamification-projects/',
        color: '#B84FB5',
        small: 'g',
        next: '/virtual-reality-innovation-projects/',
        prev: '/mobile-apps-omnichannel-ecommerce-projects/',
      },{
        letter: 'IQ',
        link: '/about/',
        color: '#666FAA',
        small: 'iq',
        next: '',
        prev: '',
      },
    ]
  }
  
  toggleHover(v) {
    const {letters} = this.state;
    if(v)
      this.setState({visible: v, color: letters.find(obj => obj.small === v).color});
    else
      this.setState({visible: v});
  }

  render () {
    const {visible, letters, changePage, color} = this.state;
    const {selected} = this.props;
    const l = letters.find(obj => obj.small === selected);

    return (
      <div className='LetterNavigation'>
        {changePage && <div className='screenFiller'>
          <CircleWrapper>
            <Circle color={color}/>
          </CircleWrapper>
          </div>
        }
        {!isMobile && <div className='LetterNavigation--letters'>
          {letters.map(letter => 
            <div key={letter.link}>
              <p 
              style={{color: selected === letter.small || visible === letter.small ? letter.color : 'white'}}
              onMouseEnter={() => this.toggleHover(letter.small)} 
              onMouseLeave={() => this.toggleHover(null)} 
              onClick={() => {this.setState({changePage: true}, 
                function () {
                  setTimeout(() => {
                    browserHistory.push(letter.link); window.location.reload()
                  }, 500);
                }
                )}}
              >
                  {letter.letter}
              </p>
            </div>
          )}
        </div>}
        
        {isMobile && 
        <div className='LetterNavigation--letters'>
          <p style={{color: selected === l.small || visible === l.small ? l.color : 'white', fontSize: 250}} >
            {l.letter}
          </p>
        </div>
        }
        <div className='LetterNavigation--parent'>
          <div className='LetterNavigation--nav' 
          onMouseEnter={() => this.setState({lightedArrow: 'left'})} 
          onMouseLeave={() => this.setState({lightedArrow: null})} 
          onClick={() => {browserHistory.push(l.prev); window.location.reload()}}>
            <p  style={{marginLeft: 15}}>PREV</p>
            <img src={this.state.lightedArrow === 'left' ? lightArr : arrowLeft} alt='backArrow'/>
          </div>
          <div className='LetterNavigation--nav' 
          onMouseEnter={() => this.setState({lightedArrow: 'right'})} 
          onMouseLeave={() => this.setState({lightedArrow: null})} 
          onClick={() => {browserHistory.push(l.next); window.location.reload()}}>
            <p>NEXT</p>
            <img src={this.state.lightedArrow === 'right' ? lightArr : arrowLeft} alt='backArrow' className='rotated' style={{marginLeft: -5}}/>
          </div>
        </div>
      </div>
    );
  }
}

export default LetterNavigation;
