import React, { Component } from 'react'
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import bck from '../../assets/logos/bck.svg';
import {browserHistory} from 'react-router';

class Footer extends Component {
  render () {
    return (
      <Row className="Footer">
        <img src={bck} alt='bck' className='Footer--back' onClick={() => this.props.onClick()}/>
        <p>All rights reserved. © Ideologiq 2022</p>
        <div className="Footer--clickable">
          <p onClick={() => browserHistory.push('/info/terms')}>Terms of use</p>
          <p onClick={() => browserHistory.push('/info/privacy')}>Privacy</p>
          <p onClick={() => browserHistory.push('/where/romania-bucharest-head-office/')} >Contact us</p>
        </div>
      </Row>
    )
  }
}

export default Footer;
