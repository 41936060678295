import React, { Component } from 'react'
import './style.scss';
import classnames from 'classnames';
import {browserHistory} from 'react-router';
import backDesktop from '../../assets/backgrounds/ideologiq_background.mp4';
import backMobile from '../../assets/backgrounds/ideologiq_background_mobile.mov';
import { Container, Row } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import StyledSVG from './background';
import LetterWrapper from './letterWrapper';

const divTransition = keyframes`
  0% {
    transform: scale();
  }
  100% {
    transform: scale(1000);
  }
  `
  
const Circle = styled.div`
  width: 40px;
  border-radius: 50%;
  background-color: ${props => props.color};
  height: 40px;
  animation: ${divTransition} 5s;
  animation-delay: ${props => props.delay}s;
`
const CircleWrapper = styled.div`
  width: 40px;
  height: 40px;
  transform: translate(${props => props.posX}px, ${props => props.posY}px);
`
const isMobile = window.innerWidth <= 500;

const letters = [
  {letter: 'I', link: '/virtual-reality-innovation-projects/', color: '#FE4443'},
  {letter: 'D', link: '/design-branding-projects/', color: '#FFB000'},
  {letter: 'E', link: '/engagement-social-media-projects/', color: '#8ABB2A'},
  {letter: 'O', link: '/SEO-CRO-UX-optimisation-projects/', color: '#CC004A'},
  {letter: 'L', link: '/loyalty-programs-projects/', color: '#00A1ED'},
  {letter: 'O', link: '/mobile-apps-omnichannel-ecommerce-projects/', color: '#03D4FF'},
  {letter: 'G', link: '/mobile-games-VR-games-gamification-projects/', color: '#B84FB5'},
  {letter: 'IQ', link: '/about/', color: '#000000'},
]

class Home extends Component {
  state={
    visible: null,
    mobileClicked: null,
    changePage: false,
    mouseX: 0,
    mouseY: 0,
    loaded: false,
    beforeLoaded: false,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({loaded: true})
    }, 2000);
    setTimeout(() => {
      this.setState({beforeLoaded: true})
    }, 4500);
  }

  toggleHover(letter, color) {
    if(letter) {
      if(!isMobile)
        this.setState({visible: letter});
      else 
        this.letterClicked(letter, color);
    }
  }

  letterClicked = (letter, color) => {
    if(!isMobile) {
      this.setState({changePage: true, color: color}, () => {
        setTimeout(() => {
          browserHistory.push(letter);
        }, 1000);
      })
    }
    else {
      this.setState({mobileClicked: letter, changePage: true, color: color}, () => {
        setTimeout(() => {
          browserHistory.push(letter);
        }, 3000);
      })
    }
  }

  render () {

    const {visible, mobileClicked, changePage, color, mouseX, mouseY, loaded, beforeLoaded} = this.state;
    console.log('Mobile Clicked', mobileClicked);
    console.log('Visible Clicked', visible);
    
    return (
      <Container fluid className="Home"  onMouseMove={(e) => this.setState({mouseX: e.nativeEvent.clientX, mouseY: e.nativeEvent.clientY})}>
        {changePage && <div className='screenFiller'>
          <CircleWrapper posX={mouseX} posY={mouseY}>
            <Circle delay={isMobile ? 1.7 : 0} color={color}  />
          </CircleWrapper>
        </div>}
        <video className='videoTag' autoPlay loop muted playsInline>
            <source src={isMobile ? backMobile : backDesktop} type='video/mp4' />
        </video>

        {!beforeLoaded && !isMobile &&
        <Row className="Home--text">
          <span className="Home--title--letters">
            <LetterWrapper letter='I' delay={3.6}></LetterWrapper>
            <LetterWrapper letter='D' delay={3.4}></LetterWrapper>
            <LetterWrapper letter='E' delay={3.2}></LetterWrapper>
            <LetterWrapper letter='O' delay={3.0}></LetterWrapper>
            <LetterWrapper letter='L' delay={2.8}></LetterWrapper>
            <LetterWrapper letter='O' delay={2.6}></LetterWrapper>
            <LetterWrapper letter='G' delay={2.4}></LetterWrapper>
            <LetterWrapper letter='I' delay={2.2}></LetterWrapper>
            <LetterWrapper letter='Q' delay={2}></LetterWrapper>
          </span>
        </Row>
        }
        {(beforeLoaded && !isMobile || isMobile && loaded) && <Row className="Home--text">
          {letters.map((letter) => 
            <div key={letter.link} className={classnames({'Home--blured': visible && visible !== letter.link})}>
              {(visible === letter.link || mobileClicked === letter.link) && <StyledSVG className="Home--image" color={letter.color}/>}
              <p 
              onMouseEnter={() => this.toggleHover(letter.link, letter.color)} 
              onMouseLeave={() => this.toggleHover(null, null)} 
              onClick={(e) => {
                this.letterClicked(letter.link, letter.color)
                }}>
                  {letter.letter}</p>
            </div>
          )}
        </Row>}
        {loaded && <Row className="Home--rational">
            <p>we know where your customers are</p>
        </Row>}
        {loaded && <Row>
          <p className="Home--playIntro" onClick={() => browserHistory.push("/intro/")}>PLAY INTRO</p>
        </Row>}
      </Container>
    )
  }
}

export default Home
