import React , { Component } from 'react';
import './App.scss';
import {browserHistory} from 'react-router';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Sidebar from "react-sidebar";
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../src/assets/logos/logo.svg';
import facebook from '../src/assets/logos/facebook.png';
import insta from '../src/assets/logos/insta.png';
import youtube from '../src/assets/logos/youtube.png';
import linkedin from '../src/assets/logos/linkedin.png';
import xSign from '../src/assets/logos/xSign.svg';
import xSignSmall from '../src/assets/logos/xSignSmall.svg';
import Cookies from 'universal-cookie';
import BurgerIcon from '../src/components/burgerIcon';
const cookies = new Cookies();
const isMobile = window.innerWidth <= 500;

const divTransition = keyframes`
  0% {
    transform: scale();
  }
  100% {
    transform: scale(500);
  }
`

const Circle = styled.div`
  width: 40px;
  border-radius: 50%;
  background-color: white;
  height: 40px;
  animation: ${divTransition} 2s;
`
const CircleWrapper = styled.div`
  width: 40px;
  height: 40px;
  transform: translate(10%, 5%);
`

Modal.setAppElement('#root');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      acceptedCookies: false,
      acceptedGDPR: false,
      loaded: false,
      lastY: 0,
      openMenu: false,
      displayMenuContent: false,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open, openMenu: open });
    setTimeout(() => {
      this.setState({displayMenuContent: open})
    }, 500);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    setTimeout(() => {
      this.setState({loaded: true});
    }, 5000);
    const acc = cookies.get('acceptedCookies');
    const accGDPR = cookies.get('acceptedGDPR');

    if(acc === 'already accepted')
      this.setState({acceptedCookies: true})
    if(accGDPR === 'already accepted')
      this.setState({acceptedGDPR: true})
  }

  render() {
    const {loaded, openMenu, displayMenuContent} = this.state;
    const { children } = this.props;
    return (
      <Sidebar
        sidebar={
          <Container fluid className="App--bar--content">
            {displayMenuContent && 
                <div className="App--bar--content--wrapper">
              <Row className="App--bar--content--closeMenu"> 
                  <img src={isMobile ? xSignSmall : xSign} onClick={() => this.onSetSidebarOpen(false)} />
              </Row>
              <Row>
                <Col xs={12} xl={6}>
                  <Row className="App--bar--content--row">
                    <p className="App--bar--content--index">01</p>
                    <p className="App--bar--content--listElem" onClick={() => {
                      browserHistory.push("/home")
                      this.onSetSidebarOpen(false)
                      }}>Ideologiq</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p className="App--bar--content--index">02</p>
                    <p className="App--bar--content--listElem" onClick={() => {
                      browserHistory.push("/about/")
                      this.onSetSidebarOpen(false)
                      }}>Why</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p className="App--bar--content--index">03</p>
                    <p className="App--bar--content--listElem" onClick={() => {
                      browserHistory.push("/areas-of-expertise/")
                      this.onSetSidebarOpen(false)
                      }}>How</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p className="App--bar--content--index">04</p>
                    <p className="App--bar--content--listElem" onClick={() => {
                      browserHistory.push("/portfolio/")
                      this.onSetSidebarOpen(false)
                      }}>What</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p className="App--bar--content--index">05</p>
                    <p className="App--bar--content--listElem" onClick={() => {
                      browserHistory.push("/where/romania-bucharest-head-office/")
                      this.onSetSidebarOpen(false)
                      }}>Where</p>
                  </Row>

                </Col>
                <Col xs={12} xl={6}>
                  <Row className="App--bar--content--emptyrow"></Row>
                  <Row className="App--bar--content--row">
                    <p className="App--bar--content--listElem" 
                    style={{marginLeft: 0}}
                    onClick={() => { 
                      browserHistory.push('/where/romania-bucharest-head-office/')
                      window.location.reload();
                      this.onSetSidebarOpen(false)
                    }}>Ideologiq Head Office</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p>21 Sperantei St 020993, District 2 Bucharest, Romania</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p className="App--bar--content--listElem" 
                    style={{marginLeft: 0}}
                    onClick={() => { 
                      browserHistory.push('/where/greece-athens-office/')
                      window.location.reload();
                      this.onSetSidebarOpen(false)
                    }}>Ideologiq Greece</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p>Αριθμός Γ.Ε.ΜΗ.: 166757306000, ΑΦΜ: 801944140 - ΔΟΥ: Α Θεσσαλονίκης, Φρίξου 15, 54627 Θεσσαλονίκη</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <p 
                    style={{color: 'orange'}} 
                    className="App--bar--contact" 
                    onClick={() => {
                      browserHistory.push('/contact/');
                    }}
                    >Contact us</p>
                  </Row>
                  <Row className="App--bar--content--row">
                    <div className="App--bar--content--media">
                      <div className="App--bar--content--media--terms">
                        <p onClick={() => {browserHistory.push('/info/terms'); this.onSetSidebarOpen(false)}} >Terms of use</p>
                        <p onClick={() => {browserHistory.push('/info/privacy'); this.onSetSidebarOpen(false)}}>Privacy</p>
                      </div>
                      <hr />
                      <div className="App--bar--content--media--social1">
                        <div className="App--bar--content--media--social2">
                          <a href="https://www.facebook.com/ideologiq/"  target="_blank">
                            <img src={facebook} alt='facebook'/>
                          </a>
                          <a href="https://www.instagram.com/ideologiq/" target="_blank">
                            <img src={insta} alt='insta' />
                          </a>
                          <a href="https://www.youtube.com/user/advancedideasro" target="_blank">
                            <img src={youtube} alt='youtube' />
                          </a>
                          <a href="https://www.linkedin.com/company/ideologiq/" target="_blank">
                            <img src={linkedin} alt='linkedin' />
                          </a>
                        </div>
                        <p>All rights reserved. © Ideologiq 2022</p>
                      </div> 
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
            }
          </Container>
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        sidebarClassName="sidebar"
      >
        <div>
          {loaded && <Row className="App--logos">
              <div  className="App--menu" onClick={() => {this.setState({openMenu: true}, function () {setTimeout(() => {
                this.onSetSidebarOpen(true)
              }, 1000)}); }}>
                <BurgerIcon loaded />
              </div>
              <img className="App--logo" src={logo} alt="Logo" onClick={() => browserHistory.push('/home')}/>
          </Row>}

          {openMenu && <div className='screenFiller'>
          <CircleWrapper>
            <Circle/>
          </CircleWrapper>
        </div>}
            {children}
          {loaded && <Row className="App--footer">
            <div className="App--questions">
              <p onClick={() => browserHistory.push('/about/')}>Why</p>
              <p onClick={() => browserHistory.push('/areas-of-expertise/')}>How</p>
              <p onClick={() => browserHistory.push('/portfolio/')}>What</p>
              <p onClick={() => browserHistory.push('/where/romania-bucharest-head-office/')}>Where</p>
            </div>
          </Row>}
          <Row className="App--cookies">
            {!this.state.acceptedCookies && <div className="App--gdpr">
              <p>
                This website uses cookies to provide the best browsing experience. By continuing browsing, you agree to the storage of cookies on your computer. More information about using cookies <a className="App--cookies--here" href="/info/privacy" style={{color: 'orange'}}>here</a>.
              </p>
              <div className="App--gdpr--accept" onClick={() => {this.setState({acceptedCookies: true}); cookies.set('acceptedCookies', 'already accepted', { path: '/' });}}>
                <p>
                  <b>Accept and continue</b>
                </p>
              </div>
            </div>
            }
          </Row>
        </div>
      </Sidebar>
    );
  }
}

App.propTypes = {
  children: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
  ])
};

export default App
