import imi1 from '../../assets/images/i/1.png';
import imi2 from '../../assets/images/i/2.png';
import imi3 from '../../assets/images/i/3.png';
import imi4 from '../../assets/images/i/4.png';
import imi5 from '../../assets/images/i/5.png';
import imi6 from '../../assets/images/i/6.png';
import imd1 from '../../assets/images/d/1.png';
import imd2 from '../../assets/images/d/2.png';
import imd3 from '../../assets/images/d/3.png';
import imd4 from '../../assets/images/d/4.png';
import imd5 from '../../assets/images/d/5.png';
import imd6 from '../../assets/images/d/6.png';
import ime1 from '../../assets/images/e/1.png';
import ime2 from '../../assets/images/e/2.png';
import ime3 from '../../assets/images/e/3.png';
import ime4 from '../../assets/images/e/4.png';
import ime5 from '../../assets/images/e/5.png';
import ime6 from '../../assets/images/e/6.png';
import imo1 from '../../assets/images/o/1.png';
import imo2 from '../../assets/images/o/2.png';
import imo3 from '../../assets/images/o/3.png';
import imo4 from '../../assets/images/o/4.png';
import imo5 from '../../assets/images/o/5.png';
import imo6 from '../../assets/images/o/6.png';
import iml1 from '../../assets/images/l/1.png';
import iml2 from '../../assets/images/l/2.png';
import iml3 from '../../assets/images/l/3.png';
import iml4 from '../../assets/images/l/4.png';
import iml5 from '../../assets/images/l/5.png';
import iml6 from '../../assets/images/l/6.png';
import imo21 from '../../assets/images/o2/1.png';
import imo22 from '../../assets/images/o2/2.png';
import imo23 from '../../assets/images/o2/3.png';
import imo24 from '../../assets/images/o2/4.png';
import imo25 from '../../assets/images/o2/5.png';
import imo26 from '../../assets/images/o2/6.png';
import img1 from '../../assets/images/g/1.png';
import img2 from '../../assets/images/g/2.png';
import img3 from '../../assets/images/g/3.png';
import img4 from '../../assets/images/g/4.png';
import img5 from '../../assets/images/g/5.png';
import img6 from '../../assets/images/g/6.png';
import I from '../../assets/letters/i.svg';
import D from '../../assets/letters/d.svg';
import E from '../../assets/letters/e.svg';
import O from '../../assets/letters/o1.svg';
import L from '../../assets/letters/l.svg';
import O2 from '../../assets/letters/o2.svg';
import G from '../../assets/letters/g.svg';
import IQ from '../../assets/letters/iq.svg';

const letters = {
    i: {
        title: 'INNOVATION',
        color: '#FE4443',
        letter: I,
        subTitle: 'Beyond ovation, beyond buzz. Into a not known land',
        firstDescriprion: [
            'No novelty should go beyond sound ethics. The world is full of waste. Wasted intelligence, wasted inspiration, wasted creativity.  We do  not pioneer  curiosities,  gimmicks, trifles:  we work  within  the rigorous frames of need and try to stay out of the pinkish realms of desire.',
            'What is not yet known at all is the inner capacity we all have: that of connecting all personal  dots and drawing the collective plan.'
        ],
        secondDescription: [
            'All tools we develop are part of a higher scope than that of primitive marketing. All our projects bear an inherent promise  of  fairness,  of  public  service.  (That  being  said,  we promise  not  to get ridiculously solemn, not to lose humor – maybe even keep a tinge of sarcasm here and there, to maintain sanity…)'
        ],
        directives: [
            {
                title: 'VR',
                description: 'Virtual Reality'
            },
            {
                title: 'AR',
                description: 'Augmented Reality'
            },
            {
                title: 'AI',
                description: 'Artificial Intelligence'
            },
            {
                title: 'BC',
                description: 'Blockchain'
            }
        ],
        images: [
            {
               img: imi1,
               title: 'Interactive Virtual Reality Training Facility',
               subTitle: 'Innovation'
            },
            {
                img: imi2,
                title: 'Interactive Virtual Reality Hypermarket Shopping Experience',
                subTitle: 'Innovation'
            },    
            {
                img: imi3,
                title: 'DRONE AERIAL FOOTAGE',
                subTitle: 'Innovation'
            },
            {
                img: imi4,
                title: 'Interactive Virtual Reality Medical Laboratories',
                subTitle: 'Innovation'
            },
            {
                img: imi5,
                title: 'H.A.R.V. Augmented Reality Office Assistant',
                subTitle: 'Innovation'
            },
            {
                img: imi6,
                title: 'A.R.M.A.N.D. Augmented Reality Trade Fair Activation',
                subTitle: 'Innovation'
            },        
        ]
    },
    d: {
        title: 'DESIGN',
        color: '#FFB000',
        letter: D,
        subTitle: 'SIGN SIGNIFICATION SIGNAL',
        firstDescriprion: [
            'We live in a world of sself-designated designers: the trouble with self-designation is it is limited to scarce satisfaction. It takes another one to know one - a good designer is always recognized by other good designers. And his or her work is userd, utilized, but so many other people who have nothing to do with design.',
            'Actually, we may even propose a riky ddefinition: the sign of a good designer is that his work is no longer signed - it has entered the daily collective life down to such depths that it no longer requires reference. History may record the author, but crowds use the object without the need for name quotation. We iron, we use cutlery, we take trains, we heal with aspirins... All our life is revolving around good design.'
        ],
        secondDescription: [
            'It is true, we mention the latest genia, but who can tell the names oh Photoshop makers? (Nobody - they are 1000 - check the credits!'    
        ],
        directives: [],
        images: [
            {
               img: imd1,
               title: 'X BY ASCENDIS',
               subTitle: 'Branding'
            },
            {
                img: imd2,
                title: 'KUBETI',
                subTitle: 'Design'
            },    
            {
                img: imd3,
                title: 'NOW DO',
                subTitle: 'Design'
            },
            {
                img: imd4,
                title: 'PORK & COMPANY',
                subTitle: 'Branding'
            },
            {
                img: imd5,
                title: 'GLU-ON',
                subTitle: 'Design'
            },
            {
                img: imd6,
                title: 'WEINMEISTER',
                subTitle: 'Concept design'
            },        
        ]
    },
    e: {
        title: 'ENGAGEMENT',
        color: '#8ABB2A',
        letter: E,
        subTitle: 'Through the screen, raising barriers',
        firstDescriprion: [
            'Flat screens are things of the past. Nowadays screens are no no longer flatly delineating a viewer from the content, a gamer from the space of the action, a reader from the author. Yes, screens still work as a surface of projection, but our own minds do the same.',
            'When we work, we cross the barrier between a rigid me and an eclosed you. Partition remains a matter of rather obsolete choice. Our writers are as much customers as our readers, the content they develop makes use of a common language – that of spontaneous action, triggering a natural reaction.'
        ],
        secondDescription: [
            'One can no longer hide anything behind a boring text - the lack of response kills any hypocrisy or attempt to disguise. We work in full light – daylight, more than screen light.'
        ],
        directives: [
            {
                title: 'SOCIAL',
                description: 'Social Media Management and Amplification'
            },
            {
                title: 'CONTENT',
                description: 'Extraordinary Content Creation'
            },
            {
                title: 'E-LEARNING',
                description: 'Platforms, content, experiences'
            }
        ],
        images: [
            {
               img: ime1,
               title: 'METHAMORFOSIS',
               subTitle: 'Engagement'
            },
            {
                img: ime2,
                title: 'MAX BET',
                subTitle: 'Engagement'
            },    
            {
                img: ime3,
                title: 'EAST & ROLL',
                subTitle: 'Engagement'
            },
            {
                img: ime4,
                title: 'BUCOVINA',
                subTitle: 'Engagement'
            },
            {
                img: ime5,
                title: 'PUFIES',
                subTitle: 'Engagement'
            },
            {
                img: ime6,
                title: 'AGRICOLA',
                subTitle: 'Engagement'
            },        
        ]
    },
    o: {
        title: 'OPTIMISATION',
        color: '#CC004A',
        letter: O,
        subTitle: 'Performance and Paid Media',
        firstDescriprion: [
            'The process of selecting the best alternative from a set of possibilities: the definition is crystal clear. The process is not! It involves human and technical functions working at highest capacity. Theoretically, a software will do it – practically, a group of special humans will develop special algorithms for special customers with super-special tools to reach special targets.',
            'Our team looks at the ecosystem of buyers, gets to understand goals and develops comprehensive performance marketing campaigns to maximize reach.'
        ],
        secondDescription: [
            'Their super-special tools and powers include SEO, Google Analytics, marketing automation, PPC, data insights, ROI optimization etc'
        ],
        directives: [
            {
                title: 'SEO',
                description: 'Search Engine Optimization'
            },
            {
                title: 'CRO',
                description: 'Conversion Rate Optimization'
            },
            {
                title: 'UX/UI',
                description: 'User Experience & User Interface Optimizazation'
            },
            {
                title: 'Lead',
                description: 'Generation'
            }
        ],
        images: [
            {
               img: imo1,
               title: 'PORSCHE INTER AUTO',
               subTitle: 'Optimisation'
            },
            {
                img: imo2,
                title: 'PORSCHE BUCURESTI',
                subTitle: 'Optimisation'
            },    
            {
                img: imo3,
                title: 'AUTOVIT',
                subTitle: 'Optimisation'
            },
            {
                img: imo4,
                title: 'OLX',
                subTitle: 'Optimisation'
            },
            {
                img: imo5,
                title: 'EJOBS',
                subTitle: 'Optimisation'
            },
            {
                img: imo6,
                title: 'RAIFFEISEN',
                subTitle: 'Optimisation'
            },        
        ]
    },
    l: {
        title: 'LOYALTY',
        color: '#00A1ED',
        letter: L,
        subTitle: 'Noblesse oblige encore…',
        firstDescriprion: [
            'Loyalty is no longer ensured through barren repetition and aggressive simplicity. A more organic connection is established between the quality of the product and the quality of the customer. Based on more than 2 or 3 attributes, these correlations are more honest and alive. And like life itself, sometimes unforgiving: fake appeals to fake, subtle to subtle, loyal to loyal.'
        ],
        secondDescription: [
            'That applies to brand loyalty. As for loyalty programs, they work on the same principles. Our team of experts strive to constantly find B2B innovative new ways to build rewards programs applicable within the specific framework Client – Supplier – Distributor. The common objective is to switch from an endless price battle to a more meaningful benefit system that consolidate long-term partnerships'
        ],
        directives: [
            {
                title: 'B2B',
                description: 'B2B Loyalty Programs'
            },
            {
                title: 'B2C',
                description: 'Consumer Loyalty Programs'
            },
            {
                title: 'EVP',
                description: 'Employee Value Proposition Loyalty Programs'
            }
        ],
        images: [
            {
               img: iml1,
               title: 'CASTROL PARTNER CLUB',
               subTitle: 'Loyalty'
            },
            {
                img: iml2,
                title: 'EMPLOYER BRANDING & EVP LOYALTY PROGRAMS',
                subTitle: 'Loyalty'
            },    
            {
                img: iml3,
                title: 'MICHELIN PARTNERS',
                subTitle: 'Loyalty'
            },
            {
                img: iml4,
                title: 'HOLCIM PLATINUM CLUB',
                subTitle: 'Loyalty'
            },
            {
                img: iml5,
                title: 'FIPO',
                subTitle: 'Loyalty'
            },
            {
                img: iml6,
                title: 'POS CUSTOMIZATION',
                subTitle: 'Loyalty'
            },        
        ]
    },
    o2: {
        title: 'OMNICHANNEL',
        color: '#03D4FF',
        letter: O2,
        subTitle: 'All encompassing 360 communication',
        firstDescriprion: [
            'To communicate is to put something in common – this is already thoroughly known. We also define what we put in common and where we do it. We facilitate e-commerce and mobile commerce – i.e. the sharing of goods, and this is not an accidental denomination - through all digital means.',
            'People are quick when it comes to getting used to miracles – first, they are in awe when the world works against natural rules for the better. Then they take it for granted: it quickly became natural to buy kintsugi pottery from Japan while sitting on a sofa in Zalău, to get tickets to Tinariwen in Morocco while in the train to Constanta, to make video calls (the dream of three generations) from a watch and so on.'
        ],
        secondDescription: [
            'To make things clear, we do not work against nature when we create this, we work with nature! With a natural tendency to protect, help and support fair trade.'
        ],
        directives: [
            {
                title: 'e-shops',
                description: 'Web, responsive and mobile app e-commerce solutions'
            },
            {
                title: 'e-CRM',
                description: 'Proprietary or open CRMs integration'
            },
            {
                title: 'POS',
                description: 'POS commerce integration'
            }
        ],
        images: [
            {
               img: imo21,
               title: 'CLICK & COLLECT',
               subTitle: 'Omnichannel'
            },
            {
                img: imo22,
                title: 'PORSCHE INTER AUTO',
                subTitle: 'Omnichannel'
            },    
            {
                img: imo23,
                title: 'IN-APP COMMERCE',
                subTitle: 'Omnichannel'
            },
            {
                img: imo24,
                title: 'WINE SHOP',
                subTitle: 'Omnichannel'
            },
            {
                img: imo25,
                title: 'CLICBETON',
                subTitle: 'Omnichannel'
            },
            {
                img: imo26,
                title: 'ENTIGHA',
                subTitle: 'Omnichannel'
            },        
        ]
    },
    g: {
        title: 'GAMIFICATION',
        color: '#B84FB5',
        letter: G,
        subTitle: 'The name of our favorite game',
        firstDescriprion: [
            'Without play, the world would be dull. Without play, there would be no world as we know it, actually. Play, in all definitions of the term, is vitality and manifestation of creativity. The rules of playing are what we need to master – not the idea of play in itself.',
            'For all these reasons, we have committed to remain children in spirit and become fully grown-ups in digital development.'
        ],
        secondDescription: [
            'By cultivating this dual personality, we get to transform anything into a game and make any product or service a playground (mainly) for adults. No matter how difficult to understand they may be, we always find a fun to make them interesting and entertaining.'
        ],
        directives: [],
        images: [
            {
               img: img1,
               title: 'INTERACTIVE VIRTUAL REALITY REWARDS',
               subTitle: 'Gamification'
            },
            {
                img: img2,
                title: 'INTERACTIVE VIRTUAL REALITY CHALLENGE',
                subTitle: 'Gamification'
            },    
            {
                img: img3,
                title: 'HEROES OF ALCOR',
                subTitle: 'Gamification'
            },
            {
                img: img4,
                title: 'DISCOUNT CITY',
                subTitle: 'Gamification'
            },
            {
                img: img5,
                title: 'SPACE ADVENTURE',
                subTitle: 'Gamification'
            },
            {
                img: img6,
                title: 'RELIGION WARS',
                subTitle: 'Gamification'
            },        
        ]
    },
}

export default letters;