import React, { Component } from 'react'
import './style.scss';
import classnames from 'classnames';
import {browserHistory} from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';
import InteractiveSections from '../../components/interactive';
import LetterWrapper from '../home/letterWrapper';

class Why extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()   // Create a ref object 
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  handleScroll = () => {
    setTimeout(() => {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  render () {
    return (
      <Container fluid className="Why" ref={this.myRef}>

        <Row className="Why--hr">
          <p>Ideologiq</p>
          <hr />
          <p>Why</p>
        </Row>
        <Row className="Why--title">
          <span className="Why--title--letters">
            <LetterWrapper className="Why--title--letter" letter='W' delay={0.9}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='H' delay={0.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='Y' delay={0.5}></LetterWrapper>
          </span>
        </Row>
        <Row className="Why--row">
          <Col xl={3} className="Why--question1">
          <p><b>I</b>deas <b>Q</b>uod erat demonstrandum</p>
          </Col>
          <Col xl={6} className="Why--description">
          <p>We believe and function in a system of ideas and ideals that we constantly test and approve in our life spheres. </p>
          <p>There’s proof that evolution of our species is due mainly to its recognized capacity to communicate.</p>
          <p>The evolution of our communication instruments is proof that brilliant ideas always become ideals for communities meant to push humankind forward.</p>
          </Col>
          <Col/>
        </Row>

        <Row className="Why--row">
          <Col xl={3} className="Why--question2">
          <p>IdeologIQ is the such a community!</p>
          </Col>
          <Col xl={6} className="Why--description">
            <p>The predictability of the IQ particle in our name is only an appearance. Actually, we chose it for its polymorphic quality. It may stand for so many other things:  from <b>Incredible Quality</b> and <b>Intelligent Qualification</b> further away into <b>Interestingly</b> Queer when appropriate.</p>
          </Col>
          <Col/>
        </Row>
        <Row className="Why--row">
          <Col xl={3} className="Why--question2">
          <p>We jump-start.</p>
          </Col>
          <Col xl={6} className="Why--description">
            <p><b>The Idle  Questioning  is  an  internal  tool  we  use  that,  paradoxically  enough,  allows  us  to  never remain dormant  or  lazy  or  too  satisfied.  We question everything: our perception, our vision, our relation, our intention. But we do not remain at this  – once the answers are given, we start.</b></p>
          </Col>
          <Col/>
        </Row>
        <Row>
          <InteractiveSections />
        </Row>
        <Row style={{height: 100}}>
          </Row>
          <Row>
          <Footer onClick={() => this.handleScroll()} />
        </Row>
      </Container>
    )
  }
}

export default Why
