import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {browserHistory} from 'react-router';
import {syncHistoryWithStore} from 'react-router-redux';
import Root from './Router';
// Storage - localstorage
import Storage from 'store';

// Initialize Storage
import expirePlugin from 'store/plugins/expire';

// Redux store
import store from './store';

// import 'bootstrap/dist/css/bootstrap.min.css';

Storage.addPlugin(expirePlugin);
window._Storage = Storage;
// Initialize react-router-redux.
const history = syncHistoryWithStore(browserHistory, store);

ReactDOM.render(<Root history={history}/>, document.getElementById('root'));
