import React, { Component } from 'react'
import './style.scss';

class ImageWithDescription extends Component {
  render () {
    const {onClick, img, title, subTitle} = this.props;
    return (
      <div className='ImageWithDescription' onClick={onClick}>
        <img src={img} alt="audi Img" className='ImageWithDescription--img'/>
        <div className='ImageWithDescription--text'>
          <p className='ImageWithDescription--text--title'>{title}</p>
          <p className='ImageWithDescription--text--subTitle'>{subTitle}</p>
        </div>
      </div>
    )
  }
}

export default ImageWithDescription;
