import React, { Component } from 'react'
import './style.scss';
import {browserHistory} from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import client from '../../assets/images/client.png';
import proprietary from '../../assets/images/proprietary.png';
import Footer from '../../components/footer';
import LetterWrapper from '../home/letterWrapper';

class What extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()   // Create a ref object 
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  handleScroll = () => {
    setTimeout(() => {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  render () {
    return (
      <Container fluid className="How" ref={this.myRef}>

        <Row className="What--hr">
          <p>Ideologiq</p>
          <hr />
          <p>What</p>
        </Row>
        <Row className="What--title">
        <span className="What--title--letters">
            <LetterWrapper className="Why--title--letter" letter='W' delay={1.1}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='H' delay={0.9}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='A' delay={0.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='T' delay={0.5}></LetterWrapper>
        </span>
        </Row>
        <Row className="What--row">
          <Col xl={6} className="What--img" onClick={() => browserHistory.push('/clients/')}>
            <img src={client}/>
            <p className="What--img--text1">CLIENTS</p>
          </Col>
          <Col xl={6} className="What--img" onClick={() => browserHistory.push('/proprietary-solutions/')}>
            <img src={proprietary}/>
            <p className="What--img--text1">SOLUTIONS</p>
          </Col>
        </Row>
        <Row>
          <Footer onClick={() => this.handleScroll()} />
        </Row>
      </Container>
    )
  }
}

export default What
