import React, { Component } from 'react'
import './style.scss';

class BurgerIcon extends Component {
  render () {
    return (
      <div className='BurgerIcon'>
        <div className='BurgerIcon--wrapper'>
          <hr className='BurgerIcon--hr1'/>
          <hr className='BurgerIcon--hr1'/>
          <hr className='BurgerIcon--hr2'/>
          <hr className='BurgerIcon--hr3'/>
          <hr className='BurgerIcon--hr3'/>
        </div>
      </div>
    )
  }
}

export default BurgerIcon;
