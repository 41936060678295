import React, { Component } from 'react'
import './style.scss';
import { CommonLoading } from 'react-loadingg';
import SkipButton from '../../components/skipButton';
import {browserHistory} from 'react-router';
import introDesktop from '../../assets/backgrounds/ideologiq_intro_mediumquality.mp4';
import introMobile from '../../assets/backgrounds/ideologiq_intro_mobile.mp4';
import soundOn from '../../assets/logos/soundOn.svg';
import soundOff from '../../assets/logos/soundOff.svg';

const isMobile = window.innerWidth <= 500;

class Intro extends Component {

  state={
    videoLoaded: false,
    muted: true
  }

  componentDidMount() {
    if (this.video) {
      this.video.addEventListener("loadeddata", () => {
        this.setState({videoLoaded: true})
        // this.video.play();
      });
      this.video.addEventListener("ended", () => {
        browserHistory.push("/home");
      });
      this.video.addEventListener("ended", () => {
        browserHistory.push("/home");
      });
    }
  }

  componentWillUnmount() {
    if (this.video) {
      this.video.removeEventListener("loadeddata", () => {
      });
      this.video.removeEventListener("ended", () => {
      });
    }
  }

  render () {
    const {videoLoaded, muted} = this.state;
    console.log(videoLoaded)
    return (
      <div className="Intro">
        {!videoLoaded && <CommonLoading color="#ffa500" />}
          <video 
          playsInline
          className='Intro--video'
          autoPlay
          muted={muted}
          ref={ref => (this.video = ref)}
          style={{
            opacity: this.state.videoLoaded ? 1 : 0,
            transition: "opacity, 2s ease-in-out"
          }}
          >
            <source src={isMobile ? introMobile : introDesktop} type='video/mp4' />
          </video>
        <div className="Intro--skipButton">
            <img src={!muted ? soundOn : soundOff} style={{margin: isMobile ? 20 : 40, width: isMobile ? 20 : 40}} onClick={() => this.setState({muted: !muted})}/>
            <SkipButton onClick={() => browserHistory.push('/home')} />
        </div>
      </div>
    )
  }
}

export default Intro
