import React, { Component } from 'react'
import './style.scss';
import {browserHistory} from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';
import bck from '../../assets/logos/arrowLeft.svg';
import LetterWrapper from '../home/letterWrapper';
import InteractiveSections from '../../components/interactive';
const rows=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
class ClientProjects extends Component {
  state = {
    pressed: false
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()   // Create a ref object 
  }

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll = () => {
    setTimeout(() => {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  render () {
    const {pressed} = this.state;
    return (
      <Container fluid className="Clients" ref={this.myRef}>

        <Row>
          <img src={bck} alt='bck' className='Clients--back' onClick={() => this.props.onClick()} onClick={() => browserHistory.push('/portfolio/')} />
        </Row>
        <Row className="Clients--hr">
          <p>Ideologiq</p>
          <hr />
          <p>Clients</p>
        </Row>
        <Row className="Clients--title">
          <span className="Clients--title--letters">
            <LetterWrapper className="Why--title--letter" letter='C' delay={1.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='L' delay={1.5}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='I' delay={1.3}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='E' delay={1.1}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='N' delay={0.9}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='T' delay={0.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='S' delay={0.5}></LetterWrapper>
          </span>
        </Row>

      {pressed ? rows.map(row =>
        <Row className="Clients--clients">
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
              <img src={require(`../../assets/clients/${row * 4 + 1}.png`)} />
            </div>
          </Col>
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
            <img src={require(`../../assets/clients/${row * 4 + 2}.png`)} />
            </div>
          </Col>
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
              <img src={require(`../../assets/clients/${row * 4 + 3}.png`)} />
            </div>
          </Col>
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
              <img src={require(`../../assets/clients/${row * 4 + 4}.png`)} />
            </div>
          </Col>
        </Row>
      ) : [0,1,2,3].map(row =>
        <Row className="Clients--clients">
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
              <img src={require(`../../assets/clients/${row * 4 + 1}.png`)} />
            </div>
          </Col>
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
            <img src={require(`../../assets/clients/${row * 4 + 2}.png`)} />
            </div>
          </Col>
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
              <img src={require(`../../assets/clients/${row * 4 + 3}.png`)} />
            </div>
          </Col>
          <Col xl={3} className='Clients--clients--col'>
            <div className='Clients--clients--parent'>
              <img src={require(`../../assets/clients/${row * 4 + 4}.png`)} />
            </div>
          </Col>
        </Row>
      )}

        <Row className='Clients--loadMore'>
          {pressed ? <p onClick={() => this.setState({pressed: !pressed})}>Load less</p> : <p onClick={() => this.setState({pressed: !pressed})}>Load more</p>}
        </Row>
        <Row>
          <InteractiveSections />
        </Row>
        
          <Row>
          <Footer onClick={() => this.handleScroll()}/>
        </Row>
      </Container>
    )
  }
}

export default ClientProjects
