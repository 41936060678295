const projects = {
    d: [
        {
            back: '/design-branding-projects/',
            title: 'X BY ASCENDIS',
            description: '',
            video: require('../../assets/projectsVideos/Design/1.mp4'),
        },
        {
            back: '/design-branding-projects/',
            title: 'KUBETI',
            description: '',
            video: require('../../assets/projectsVideos/Design/2.mp4'),
        },
        {
            back: '/design-branding-projects/',
            title: 'NOW DO',
            description: '',
            video: require('../../assets/projectsVideos/Design/3.mp4'),
        },
        {
            back: '/design-branding-projects/',
            title: 'PORK & COMPANY',
            description: '',
            video: require('../../assets/projectsVideos/Design/4.mp4'),
        },
        {
            back: '/design-branding-projects/',
            title: 'GLU-ON',
            description: '',
            video: require('../../assets/projectsVideos/Design/5.mp4'),
        },
        {
            back: '/design-branding-projects/',
            title: 'WEINMEISTER',
            description: '',
            video: require('../../assets/projectsVideos/Design/6.mp4'),
        },
    ],
    e: [
        {
            back: '/engagement-social-media-projects/',
            title: 'METHAMORFOSIS',
            description: '',
            video: require('../../assets/projectsVideos/Engagement/1.mp4'),
        },
        {
            back: '/engagement-social-media-projects/',
            title: 'MAX BET',
            description: '',
            video: require('../../assets/projectsVideos/Engagement/2.mp4'),
        },
        {
            back: '/engagement-social-media-projects/',
            title: 'EAST & ROLL',
            description: '',
            video: require('../../assets/projectsVideos/Engagement/3.mp4'),
        },
        {
            back: '/engagement-social-media-projects/',
            title: 'BUCOVINA',
            description: '',
            video: require('../../assets/projectsVideos/Engagement/4.mp4'),
        },
        {
            back: '/engagement-social-media-projects/',
            title: 'PUFIES',
            description: '',
            video: require('../../assets/projectsVideos/Engagement/5.mp4'),
        },
        {
            back: '/engagement-social-media-projects/',
            title: 'AGRICOLA',
            description: '',
            video: require('../../assets/projectsVideos/Engagement/6.mp4'),
        },
    ],
    i: [
        {
            back: '/virtual-reality-innovation-projects/',
            title: 'Interactive Virtual Reality Training Facility',
            description: '',
            video: require('../../assets/projectsVideos/Innovation/1.mp4'),
        },
        {
            back: '/virtual-reality-innovation-projects/',
            title: 'Interactive Virtual Reality Hypermarket Shopping Experience',
            description: '',
            video: require('../../assets/projectsVideos/Innovation/2.mp4'),
        },
        {
            back: '/virtual-reality-innovation-projects/',
            title: 'DRONE AERIAL FOOTAGE',
            description: '',
            video: require('../../assets/projectsVideos/Innovation/3.mp4'),
        },
        {
            back: '/virtual-reality-innovation-projects/',
            title: 'Interactive Virtual Reality Medical Laboratories',
            description: '',
            video: require('../../assets/projectsVideos/Innovation/4.mp4'),
        },
        {
            back: '/virtual-reality-innovation-projects/',
            title: 'H.A.R.V. Augmented Reality Office Assistant',
            description: '',
            video: require('../../assets/projectsVideos/Innovation/5.mp4'),
        },
        {
            back: '/virtual-reality-innovation-projects/',
            title: 'A.R.M.A.N.D. Augmented Reality Trade Fair Activation',
            description: '',
            video: require('../../assets/projectsVideos/Innovation/6.mp4'),
        },
    ],
    l: [
        {
            back: '/loyalty-programs-projects/',
            title: 'CASTROL PARTNER CLUB',
            description: '',
            video: require('../../assets/projectsVideos/Loyalty/1.mp4'),
        },
        {
            back: '/loyalty-programs-projects/',
            title: 'EMPLOYER BRANDING & EVP LOYALTY PROGRAMS',
            description: '',
            video: require('../../assets/projectsVideos/Loyalty/2.mp4'),
        },
        {
            back: '/loyalty-programs-projects/',
            title: 'MICHELIN PARTNERS',
            description: '',
            video: require('../../assets/projectsVideos/Loyalty/3.mp4'),
        },
        {
            back: '/loyalty-programs-projects/',
            title: 'HOLCIM PLATINUM CLUB',
            description: '',
            video: require('../../assets/projectsVideos/Loyalty/4.mp4'),
        },
        {
            back: '/loyalty-programs-projects/',
            title: 'FIPO',
            description: '',
            video: require('../../assets/projectsVideos/Loyalty/5.mp4'),
        },
        {
            back: '/loyalty-programs-projects/',
            title: 'POS CUSTOMIZATION',
            description: '',
            video: require('../../assets/projectsVideos/Loyalty/6.mp4'),
        },
    ],
    o2: [
        {
            back: '/mobile-apps-omnichannel-ecommerce-projects/',
            title: 'CLICK & COLLECT',
            description: '',
            video: require('../../assets/projectsVideos/Omnichannel/1.mp4'),
        },
        {
            back: '/mobile-apps-omnichannel-ecommerce-projects/',
            title: 'PORSCHE INTER AUTO',
            description: '',
            video: require('../../assets/projectsVideos/Omnichannel/2.mp4'),
        },
        {
            back: '/mobile-apps-omnichannel-ecommerce-projects/',
            title: 'IN-APP COMMERCE',
            description: '',
            video: require('../../assets/projectsVideos/Omnichannel/3.mp4'),
        },
        {
            back: '/mobile-apps-omnichannel-ecommerce-projects/',
            title: 'WINE SHOP',
            description: '',
            video: require('../../assets/projectsVideos/Omnichannel/4.mp4'),
        },
        {
            back: '/mobile-apps-omnichannel-ecommerce-projects/',
            title: 'CLICBETON',
            description: '',
            video: require('../../assets/projectsVideos/Omnichannel/5.mp4'),
        },
        {
            back: '/mobile-apps-omnichannel-ecommerce-projects/',
            title: 'ENTIGHA',
            description: '',
            video: require('../../assets/projectsVideos/Omnichannel/6.mp4'),
        },
    ],
    o: [
        {
            back: '/SEO-CRO-UX-optimisation-projects/',
            title: 'PORSCHE INTER AUTO',
            description: '',
            video: require('../../assets/projectsVideos/Optimisation/1.mp4'),
        },
        {
            back: '/SEO-CRO-UX-optimisation-projects/',
            title: 'PORSCHE BUCURESTI',
            description: '',
            video: require('../../assets/projectsVideos/Optimisation/2.mp4'),
        },
        {
            back: '/SEO-CRO-UX-optimisation-projects/',
            title: 'AUTOVIT',
            description: '',
            video: require('../../assets/projectsVideos/Optimisation/3.mp4'),
        },
        {
            back: '/SEO-CRO-UX-optimisation-projects/',
            title: 'OLX',
            description: '',
            video: require('../../assets/projectsVideos/Optimisation/4.mp4'),
        },
        {
            back: '/SEO-CRO-UX-optimisation-projects/',
            title: 'EJOBS',
            description: '',
            video: require('../../assets/projectsVideos/Optimisation/5.mp4'),
        },
        {
            back: '/SEO-CRO-UX-optimisation-projects/',
            title: 'RAIFFEISEN',
            description: '',
            video: require('../../assets/projectsVideos/Optimisation/6.mp4'),
        },
    ],
    g: [
        {
            back: '/mobile-games-VR-games-gamification-projects/',
            title: 'INTERACTIVE VIRTUAL REALITY REWARDS',
            description: '',
            video: require('../../assets/projectsVideos/Gamification/1.mp4'),
        },
        {
            back: '/mobile-games-VR-games-gamification-projects/',
            title: 'INTERACTIVE VIRTUAL REALITY CHALLENGE',
            description: '',
            video: require('../../assets/projectsVideos/Gamification/2.mp4'),
        },
        {
            back: '/mobile-games-VR-games-gamification-projects/',
            title: 'HEROES OF ALCOR',
            description: '',
            video: require('../../assets/projectsVideos/Gamification/3.mp4'),
        },
        {
            back: '/mobile-games-VR-games-gamification-projects/',
            title: 'DISCOUNT CITY',
            description: '',
            video: require('../../assets/projectsVideos/Gamification/4.mp4'),
        },
        {
            back: '/mobile-games-VR-games-gamification-projects/',
            title: 'SPACE ADVENTURE',
            description: '',
            video: require('../../assets/projectsVideos/Gamification/5.mp4'),
        },
        {
            back: '/mobile-games-VR-games-gamification-projects/',
            title: 'RELIGION WARS',
            description: '',
            video: require('../../assets/projectsVideos/Gamification/6.mp4'),
        },
    ],
}

export default projects;