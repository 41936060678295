import React, { Component } from 'react'
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import close from '../../assets/logos/xSign.svg';
import {browserHistory} from 'react-router';

const BASE_URL = window.BASE_URL;
const RECEIVER = window.RECEIVER;

class Join extends Component {

  state= {
    name: '',
    email: '',
    phone: '',
    message: '',
    success: false,
    error: false,
    selectedFile: null,
  }

  handleChange = (event, field) => {
    if(field === 'file') {
      this.handleFileChosen(event.target.files[0]);
    }
    else {
      this.setState({[field]: event.target.value});
      console.log(event)
    }
  }

  handleFileChosen = (file) => {
    let fileReader = new FileReader();
    fileReader.onloadend =  (e) => {
      const content = fileReader.result;
      console.log(fileReader);
      console.log(content);
      this.setState({selectedFile: content});
    }
    fileReader.readAsDataURL(file);
  }

  handleSubmit() {
    const {name, email, phone, message} = this.state;

    let data = {
      client: 'ideologiq',
      secret: 'this@ismySECRETk3y2..2'
  };
    if(name && name != '' &&
    email && email != '' &&
    message && message != '' &&
    phone && phone != '' 
    ) {
      fetch(`${BASE_URL}/api/authenticate`, {
        method: 'POST', // or 'PUT'
        cors: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(res => res.json()).then(res => {
        if(res && res.token && res.token !== '') {

          fetch(`${BASE_URL}/api/sendEmail`, {
            method: 'POST', // or 'PUT'
            cors: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: RECEIVER,
              content: `${this.state.name} cu adresa de email: ${this.state.email} si numarul de telefon: ${this.state.phone}, a trimis mesajul: ${this.state.message}`,
              subject: 'Ideologiq join us',
              text: 'Asta nu stiu ce e',
              token: res.token,
              attachments: this.state.selectedFile,
            }),
          }).then(res => res.json()).then(res => {
            if(res.message) {
              this.setState({success: true, error: false}, () => {setTimeout(() => {
                browserHistory.push('/home');
              }, 2000);})
            }
          })

        }
      })
    }
    else {
      this.setState({success: false, error: true})
    }
  }

  render () {
    const {success, error, selectedFile} = this.state;
    return (
      <Container fluid className="Join" ref={this.myRef}>
        <img src={close} alt='close' className="Join--close" onClick={() => browserHistory.goBack()}/>
        <Row>
          <Col xl={4} xs={12} className="Join--info">
            <p className='Join--info--firstText'>Want to</p>
            <p className='Join--info--secondText'>JOIN US?</p>
            <p className='Join--info--thirdText'>Here at Ideologiq, we rely on strategic outsights to kick-off the ideation process for any project that we’re tackling. From there onwards, we’re delving into the depths of the issue that is to be addressed in order to reach that one meaningfully relevant insight upon which the final creative materials shall be based.</p>
            <div className='Join--info--rectangle'/>
          </Col>
          <Col xl={4} xs={12} className="Join--center">
            <div className='Join--center--wrapper'>
              <div className="Join--center--joinOverlay">
                {selectedFile ? 
                <p>
                  CV UPLOADED
                </p> :
                <p>
                UPLOAD CV
              </p>}
              </div>
              <input type="file" accept="application/pdf, application/vnd.ms-doc, application/vnd.ms-docx" onChange={(event) => this.handleChange(event, 'file')} className="Join--center--join"/>
            </div>
          </Col>
          <Col xl={4} xs={12} className="Join--right">
          <form className="Join--right--form">
              <input type="text" value={this.state.name} onChange={(event) => this.handleChange(event, 'name')} placeholder='Name/Surname' className="Join--right--input"/>
              <input type="text" value={this.state.email} onChange={(event) => this.handleChange(event, 'email')} placeholder='Email' className="Join--right--input"/>
              <input type="text" value={this.state.phone} onChange={(event) => this.handleChange(event, 'phone')} placeholder='Telephone' className="Join--right--input"/>
              <label style={{color: 'gray', fontSize:24}}>Message</label>
              <textarea type="text" value={this.state.message} onChange={(event) => this.handleChange(event, 'message')} className="Join--right--message"/>
            <p className='Join--right--submit' onClick={() => this.handleSubmit()}>SUBMIT</p>
          </form>
          {success && <p style={{color: 'green', justifyContent: 'center', width: '100%', fontSize: 24, textAlign: 'center', fontWeight: 600}}>
            Thank you for your message!
          </p>}
          {error && <p style={{color: 'red', justifyContent: 'center', width: '100%', fontSize: 24, textAlign: 'center', fontWeight: 600}}>
            Please complete all fields!
          </p>}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Join
