import React, { Component } from 'react'
import './style.scss';
import logo from '../../assets/logos/logo.svg';
import {browserHistory} from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';

class Cover extends Component {
  render () {
    return (
      <Container fluid className="Cover" onClick={() => browserHistory.push('/intro/')}>
        <Row style={{height: '40%'}}></Row>  
        <Row>
          <Col xl={4}/>
          <Col xl={4} className="Cover--website">
            <p>www.ideologiq.com</p>
          </Col>
          <Col xl={4}/>
        </Row>
        <Row>
          <Col xl={4}/>
          <Col xl={4} className="Cover--website">
            <p>Ideologiq</p>
          </Col>
          <Col xl={4}/>
        </Row>
        <Row className="Cover--logo--row">
          <img className="Cover--logo" src={logo} alt="Logo" />
        </Row>
      </Container>
    )
  }
}

export default Cover
