import React, { Component } from 'react'
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import challenge from '../../assets/images/challengeus.png';
import challenge2 from '../../assets/images/challenge.png';
import join from '../../assets/images/joinus.png';
import {browserHistory} from 'react-router';
import styled, { keyframes } from 'styled-components';

const divTransition = keyframes`
  0% {
    transform: scale();
  }
  100% {
    transform: scale(500);
  }
`

const Circle = styled.div`
  width: 40px;
  border-radius: 50%;
  background-color: white;
  height: 40px;
  animation: ${divTransition} 2s;
`
const CircleWrapper = styled.div`
  width: 40px;
  height: 40px;
  transform: translate(10%, 5%);
`

class InteractiveSections extends Component {

  state= {
    changePage: false
  }

  render () {
    const {changePage} = this.state;
    return (

      <Container fluid className='noMargin'>        
        {changePage && <div className='screenFiller'>
          <CircleWrapper>
            <Circle/>
          </CircleWrapper>
          </div>
        }
      {!changePage && <div> {!this.props.letter ?
      <Row className="InteractiveSections">
        <Col xl={6} className='noMargin'>
          <div className='InteractiveSections--imageWithDescription' onClick={() => {this.setState({changePage: true}, 
          function () {
            setTimeout(() => {
              browserHistory.push('/contact/')
            }, 1000);
          }
          )}}>
            <img src={challenge} alt="audi Img" className='InteractiveSections--imageWithDescription--img'/>
            <div className='InteractiveSections--imageWithDescription--text'>
              <p>Want to <br/> <b>Challenge</b> us?</p>
            </div>
          </div>
        </Col>
        <Col xl={6} className='noMargin'>
        <div className='InteractiveSections--imageWithDescription' onClick={() => {this.setState({changePage: true}, 
          function () {
            setTimeout(() => {
              browserHistory.push('/join/')
            }, 1000);
          }
          )}}>
            <img src={join} alt="audi Img" className='InteractiveSections--imageWithDescription--img'/>
            <div className='InteractiveSections--imageWithDescription--text'>
              <p>Want to join our <br/><b>team</b>?</p>
            </div>
          </div>
        </Col>
      </Row>
      :
      <Row className="InteractiveSections">
        {changePage && <div className='screenFiller'>
          <CircleWrapper>
            <Circle/>
          </CircleWrapper>
        </div>}
        <Col xl={12} className='InteractiveSections--imageWithDescription--col'>
        <div className='InteractiveSections--imageWithDescription' onClick={() => {this.setState({changePage: true}, 
          function () {
            setTimeout(() => {
              browserHistory.push('/contact/')
            }, 1000);
          }
          )}}>
          <img src={challenge2} alt="audi Img" className='InteractiveSections--imageWithDescription--img'/>
          <div className='InteractiveSections--imageWithDescription--text2'>
            <p>Want to <br/> <b>Challenge</b> us?</p>
          </div>
        </div>
        </Col>
      </Row>}
      </div>}
      </Container>
    )
  }
}

export default InteractiveSections;
