import React, { Component } from 'react'
import './style.scss';
import {browserHistory} from 'react-router';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';
import bck from '../../assets/logos/arrowLeft.svg';
import LetterWrapper from '../home/letterWrapper';
import InteractiveSections from '../../components/interactive';

class ProprietaryProjects extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()   // Create a ref object 
  }

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll = () => {
    setTimeout(() => {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  render () {
    return (
      <Container fluid className="Proprietary" ref={this.myRef}>
        <Row>
          <img src={bck} alt='bck' className='Clients--back' onClick={() => this.props.onClick()} onClick={() => browserHistory.push('/portfolio/')} />
        </Row>
        <Row className="Proprietary--hr">
          <p>Ideologiq</p>
          <hr />
          <p>Solutions</p>
        </Row>
        <Row className="Proprietary--title">
          <span className="Proprietary--title--letters">
            <LetterWrapper className="Why--title--letter" letter='S' delay={2.1}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='O' delay={1.9}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='L' delay={1.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='U' delay={1.5}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='T' delay={1.3}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='I' delay={1.1}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='O' delay={0.9}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='N' delay={0.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='S' delay={0.5}></LetterWrapper>
          </span>
        </Row>
        <Row className="Proprietary--subtitle">
          <p>Proprietary Apps</p>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/wrap.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol">
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/entiga.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol" style={{backgroundColor: '#0F0F0F'}}>
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
        <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/wrapmail.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol">
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>

        <Row className="Proprietary--subtitle">
          <p>Proprietary Frameworks</p>
        </Row>

        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/VRFramework.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol">
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/GAMEFramework.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol" style={{backgroundColor: '#0F0F0F'}}>
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/streaming.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol">
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/loyalty.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol" style={{backgroundColor: '#0F0F0F'}}>
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/ecommerce.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol">
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/learning.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol" style={{backgroundColor: '#0F0F0F'}}>
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row className="Proprietary--subtitle">
          <p>Proprietary Processes</p>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/5d.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol">
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/digital.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol" style={{backgroundColor: '#0F0F0F'}}>
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={6} className="Proprietary--leftCol">
            <img src={require('../../assets/solutions/fericirii.png')} alt='img' />
          </Col>
          <Col xl={6} className="Proprietary--rightCol">
            <p>As a digitally-centric marcomms agency, we’re here to bring our contribution to the ever-changing nature of the 21st century integrated communication paradigm on a European level by incorporating innovation in all of our projects.</p>
          </Col>
        </Row>

        <Row>
          <InteractiveSections />
        </Row>
        <Row style={{height: 100}}>
          </Row>
          <Row>
          <Footer onClick={() => this.handleScroll()} />
        </Row>
      </Container>
    )
  }
}

export default ProprietaryProjects
