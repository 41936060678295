import React, { Component } from 'react'
import './style.scss';

class Card extends Component {
  state = {
    visible: false
  }
  toggleHover(val) {
    this.setState({visible:val});
  }
  render () {
    const {onClick, title, description, color} = this.props;
    const {visible} = this.state;
    return (
      <div 
      onTouchStart={(e) => {
        this.setState({visible: true})
      }}
      onTouchEnd={(e) => {
        this.setState({visible: false})
      }}
      className='Card' 
      onClick={onClick}
      onMouseEnter={() => this.toggleHover(true)} 
      onMouseLeave={() => this.toggleHover(false)} 
      style={{backgroundColor: visible ? color : '#1D1D1D'}}
      >
        <p className='Card--title'>{title}</p>
        <p className='Card--description'>{description}</p>
      </div>
    )
  }
}

export default Card;
