import React, { Component } from 'react'
import './style.scss';
import { CommonLoading } from 'react-loadingg';

class SkipButton extends Component {
  render () {
    const {onClick} = this.props;

    return (
      <div className="skip-button" onClick={onClick}>
        <p>skip intro</p>
        <div className="rectangle" />
      </div>
    )
  }
}

export default SkipButton
