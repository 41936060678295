import React, { Component } from 'react'
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';

class InfoPage extends Component {

  constructor(props) {
    super(props)
    this.myRef = React.createRef()   // Create a ref object 
  }

  componentDidMount = () => {
    this.handleScroll();
  }

  componentDidUpdate = () => {
    this.handleScroll();
  }

  handleScroll = () => {
    setTimeout(() => {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  toggleHover(color) {
    this.setState({visible: color});
  }

  render () {
    const {info} = this.props.params;
    return (
      
      <Container fluid className="InfoPage" ref={this.myRef}>
  {info === 'privacy' ? (
  <div><Row className="InfoPage--hr">
          <p>Ideologiq</p>
          <hr />
          <p>Privacy</p>
        </Row>
        <span className="InfoPage--title--letters">
            <span className="InfoPage--title--letter">P</span>
            <span className="InfoPage--title--letter">R</span>
            <span className="InfoPage--title--letter">I</span>
            <span className="InfoPage--title--letter">V</span>
            <span className="InfoPage--title--letter">A</span>
            <span className="InfoPage--title--letter">C</span>
            <span className="InfoPage--title--letter">Y</span>
          </span>

        <Row className="InfoPage--row">
          <Col xl={3} className="InfoPage--question2">
          <p>What Are Cookies</p>
          </Col>
          <Col xl={6} className="InfoPage--description">
            <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.
              For more general information on cookies see the Wikipedia article on HTTP Cookies.
            </p>
          </Col>
          <Col/>
        </Row>

        <Row className="InfoPage--row">
          <Col xl={3} className="InfoPage--question2">
          <p>How We Use Cookies</p>
          </Col>
          <Col xl={6} className="InfoPage--description">
            <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.
            </p>
          </Col>
          <Col/>
        </Row>

        <Row className="InfoPage--row">
          <Col xl={3} className="InfoPage--question2">
          <p>Disabling Cookies</p>
          </Col>
          <Col xl={6} className="InfoPage--description">
            <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
          </Col>
          <Col/>
        </Row>

        <Row className="InfoPage--row">
          <Col xl={3} className="InfoPage--question2">
          <p>The Cookies We Set</p>
          </Col>
          <Col xl={6} className="InfoPage--description">
            <p>When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>
          </Col>
          <Col/>
        </Row>

        <Row className="InfoPage--row">
          <Col xl={3} className="InfoPage--question2">
          <p>Third Party Cookies</p>
          </Col>
          <Col xl={6} className="InfoPage--description">
            <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.
              <br/>For more information on Google Analytics cookies, see the official Google Analytics page.
            </p>
          </Col>
          <Col/>
        </Row>

        <Row className="InfoPage--row">
          <Col xl={3} className="InfoPage--question2">
          <p>Contact form submissions</p>
          </Col>
          <Col xl={6} className="InfoPage--description">
            <p>If you contact us via the contact form, you agree that we can use your data provided in the form to contact you in the scope of answering your inquire.</p>
          </Col>
          <Col/>
        </Row>

        <Row className="InfoPage--row">
          <Col xl={3} className="InfoPage--question2">
          <p>More Information</p>
          </Col>
          <Col xl={6} className="InfoPage--description">
            <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.
              <br/>However if you are still looking for more information then you can contact us by e-mail at office@ideologiq.com.
            </p>
          </Col>
          <Col/>
        </Row>

        <Row style={{height: 100}}>
          </Row>
          <Row>
          <Footer onClick={() => this.handleScroll()} />
        </Row> </div>)
      :
      (<div><Row className="InfoPage--hr">
        <p>Ideologiq</p>
        <hr />
        <p>Terms of use</p>
      </Row>
      <span className="What--title--letters">
            <span className="What--title--letter">T</span>
            <span className="What--title--letter">E</span>
            <span className="What--title--letter">R</span>
            <span className="What--title--letter">M</span>
            <span className="What--title--letter">S</span>
          </span>
      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Introduction</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
            These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, IDEOLOGIQ accessible at www.ideologiq.com.
          </p>
          <p>
            These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
          </p>
          <p>
            Minors or people below 18 years old are not allowed to use this Website.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Intellectual Property Rights</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
            Other than the content you own, under these Terms, Advanced Ideas Studio SRL and/or its licensors own all the intellectual property rights and materials contained in this Website.
          </p>
          <p>
           You are granted limited license only for purposes of viewing the material contained on this Website.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Restrictions</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          You are specifically restricted from all of the following:
          </p>
          <p>
          - publishing any Website material in any other media;
          </p>
          <p>
          - selling, sublicensing and/or otherwise commercializing any Website material;
          </p>
          <p>
          - publicly performing and/or showing any Website material;
          </p>
          <p>
          - using this Website in any way that is or may be damaging to this Website;
          </p>
          <p>
          - using this Website in any way that impacts user access to this Website;
          </p>
          <p>
          - using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;
          </p>
          <p>
          - engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;
          </p>
          <p>
          - using this Website to engage in any advertising or marketing.
          </p>
          <p>
          Certain areas of this Website are restricted from being access by you and Advanced Ideas Studio SRL may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Your Content</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Advanced Ideas Studio SRL a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
          </p>
          <p>
          Your Content must be your own and must not be invading any third-party's rights. Advanced Ideas Studio SRL reserves the right to remove any of Your Content from this Website at any time without notice.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>No warranties</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          This Website is provided “as is,” with all faults, and Advanced Ideas Studio SRL express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Limitation of liability</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          In no event shall Advanced Ideas Studio SRL, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  Advanced Ideas Studio SRL, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Indemnification</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          You hereby indemnify to the fullest extent Advanced Ideas Studio SRL from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Severability</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Variation of Terms</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          Advanced Ideas Studio SRL is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Assignment</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          Advanced Ideas Studio SRL is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Entire Agreement</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          These Terms constitute the entire agreement between Advanced Ideas Studio SRL and you in relation to your use of this Website, and supersede all prior agreements and understandings.
          </p>
        </Col>
        <Col/>
      </Row>

      <Row className="InfoPage--row">
        <Col xl={3} className="InfoPage--question2">
        <p>Governing Law {'&'} Jurisdiction</p>
        </Col>
        <Col xl={6} className="InfoPage--description">
          <p>
          These Terms will be governed by and interpreted in accordance with the laws of the Romania and the European Union, and you submit to the non-exclusive jurisdiction of the state of Romania for the resolution of any disputes.
          </p>
        </Col>
        <Col/>
      </Row>

      

      <Row style={{height: 100}}>
        </Row>
        <Row>
        <Footer onClick={() => this.handleScroll()} />
      </Row></div>)
    }
    </Container>
    )
  }
}

export default InfoPage
