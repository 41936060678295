import React, { Component } from 'react'
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import close from '../../assets/logos/xSign.svg';
import {browserHistory} from 'react-router';


const BASE_URL = window.BASE_URL;
const RECEIVER = window.RECEIVER;

class Contact extends Component {

  state= {
    name: '',
    email: '',
    phone: '',
    message: '',
    success: false,
    error: false,
  }

  handleChange = (event, field) => {
    this.setState({[field]: event.target.value});
    console.log(event)
  }

  handleSubmit() {
    const {name, email, phone, message} = this.state;

    let data = {
      client: 'ideologiq',
      secret: 'this@ismySECRETk3y2..2'
  };
    if(name && name != '' &&
    email && email != '' &&
    message && message != '' &&
    phone && phone != '' 
    ) {
      fetch(`${BASE_URL}/api/authenticate`, {
        method: 'POST', // or 'PUT'
        cors: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(res => res.json()).then(res => {
        if(res && res.token && res.token !== '') {

          fetch(`${BASE_URL}/api/sendEmail`, {
            method: 'POST', // or 'PUT'
            cors: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: RECEIVER,
              content: `${this.state.name} cu adresa de email: ${this.state.email} si numarul de telefon: ${this.state.phone}, a trimis mesajul: ${this.state.message}`,
              subject: 'Ideologiq contact us',
              text: 'Asta nu stiu ce e',
              token: res.token
            }),
          }).then(res => res.json()).then(res => {
            if(res.message) {
              this.setState({success: true, error: false}, () => {setTimeout(() => {
                browserHistory.push('/home');
              }, 2000);})
            }
          })

        }
      })
    }
    else {
      this.setState({success: false, error: true})
    }
  }

  render () {
    const {success, error} = this.state;
    return (
      <Container fluid className="Contact" ref={this.myRef}>
        <img src={close} alt='close' className="Contact--close" onClick={() => browserHistory.goBack()}/>
        <Row>
          <Col xl={6} xs={12} className="Contact--info">
            <p className='Contact--info--firstText'>Want to</p>
            <p className='Contact--info--secondText'>CHALLENGE US?</p>
            <p className='Contact--info--thirdText'>Do you have a challenge for us, do you want to join our team or you just want to chat with someone about the future of digitization and digital marketing? Send us a message and we'll get back to you.
            </p>
            <div className='Contact--info--rectangle'/>
          </Col>
          <Col xl={6} xs={12} className="Contact--right">
          <form className="Contact--right--form">
              <input type="text" value={this.state.name} onChange={(event) => this.handleChange(event, 'name')} placeholder='Name/Surname' className="Contact--right--input"/>
              <input type="text" value={this.state.email} onChange={(event) => this.handleChange(event, 'email')} placeholder='Email' className="Contact--right--input"/>
              <input type="text" value={this.state.phone} onChange={(event) => this.handleChange(event, 'phone')} placeholder='Telephone' className="Contact--right--input"/>
              <label style={{color: 'gray', fontSize:24}}>Message</label>
              <textarea type="text" value={this.state.message} onChange={(event) => this.handleChange(event, 'message')} className="Contact--right--message"/>
            <p className='Contact--right--submit' onClick={() => this.handleSubmit()}>SUBMIT</p>
          </form>
          {success && <p style={{color: 'green', justifyContent: 'center', width: '100%', fontSize: 24, textAlign: 'center', fontWeight: 600}}>
            Thank you for your message!
          </p>}
          {error && <p style={{color: 'red', justifyContent: 'center', width: '100%', fontSize: 24, textAlign: 'center', fontWeight: 600}}>
            Please complete all fields!
          </p>}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Contact
