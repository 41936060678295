import React, { Component } from 'react'
import './style.scss';
import classnames from 'classnames';
import {browserHistory} from 'react-router';
import Modal from 'react-modal';
import HowOverlay from '../howOverlay';
import Card from '../../components/card';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/footer';
import LetterWrapper from '../home/letterWrapper';
import InteractiveSections from '../../components/interactive';
Modal.setAppElement('#root');

const overlayContent = [
  {
    title: 'SEMANTIQ', 
    backGroundColor: '#00A1ED',
    subtitle: 'copywriting & discourse',
    text: ['Concept copywriting',  'Brand/Product name creation', 'Content creation', 'Community management', 'Social media management', 'Online activism'],
  },
  {
    title: 'HEURISTIQ', 
    backGroundColor: '#76187E',
    subtitle: 'insights & strategy',
    text: ['Marketing communication strategy',  'Advertising strategy', 'Brand positioning strategy', 'Product positioning strategy', 'Enterprise digitization programs',
     'Social media strategy', 'Content strategy', 'Communication concept & direction', 'Loyalty programs strategy and mechanics', 'Employer Branding strategy', 'Employee Value Proposition Programs and mechanics'],
  },
  {
    title: 'KINETIQ', 
    backGroundColor: '#8ABB2A',
    subtitle: 'events & consumer activation',
    text: ['Digital consumer activation',  'Fairs & events organization', 'Digital boots creation, implementation & logistics', 'Event management'],
  },
  {
    title: 'REALISTIQ', 
    backGroundColor: '#D02A66',
    subtitle: 'software & development',
    text: ['Web portals', 'Product websites', 'Consumer engagement websites', 'Consumer e-commerce solutions', 'Business to business e-commerce solutions',
        'Mobile Apps', 'Business to business loyalty platforms', 'Consumer loyalty platforms', 'CRM solutions development', 'Data Repository and marketing automation solutions development'],
  },
  {
    title: 'CENTRIQ', 
    backGroundColor: '#F77E0B',
    subtitle: 'identity & branding',
    text: ['Brand concept',  'Rebranding', 'Identity systems'],
  },
  {
    title: 'IDEATIQ', 
    backGroundColor: '#E50019',
    subtitle: 'innovation & tech',
    text: ['Augmented Reality solutions',  'Interactive Virtual Reality solutions', '360 Virtual Reality production', '3D & Isometric gaming platforms', 'Artificial Intelligence', ' Blockchain platforms'],
  },
  {
    title: 'ATLETIQ',
    backGroundColor: '#289238',
    subtitle: 'sportsmarketing & management',
    text: ['Sports market research',  'Sports organizations marketing strategy', 'Athlete branding', 'Digital marketing', 'Partnership and sponsorship in sports', 'Sports marketing events'],
  },
  {
    title: 'MAGNETIQ', 
    backGroundColor: '#794A2C',
    subtitle: 'media & performance',
    text: ['Digital Performance Management programs',  'SEO, UX/UI & CRO Audits and Optimization', 'Media planning, buying & management', 'Reporting & Analytics'],
  },
  {
    title: 'GEOMETRIQ', 
    backGroundColor: '#002B7F',
    subtitle: 'visuals & art',
    text: ['Marketing communication campaigns',  '3D modeling & animation', 'Graphic design', 'Artwork & Illustration', 'Branding concept', 'Brand identity systems',
      'Video content acquisition', 'Video CGI/animation', 'Multimedia (A/V) content production'],
  },
]

class How extends Component {
  state={
    visible: 0,
    modalIsOpen: false,
    lastVisible: 0,
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef()   // Create a ref object 
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  handleScroll = () => {
    setTimeout(() => {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 10)
  }

  toggleHover(color) {
    this.setState({visible: color});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }
  openModal = (cifra) => {
    this.setState({lastVisible: cifra, modalIsOpen: true});
  }

  render () {
    const {visible, modalIsOpen, lastVisible} = this.state;
    return (
      <Container fluid className="How" ref={this.myRef}>
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        className='How--modal'
        style={{content: {overflow:'scroll'}}}
        >
          <HowOverlay 
          title={overlayContent[lastVisible].title}
          backGroundColor={overlayContent[lastVisible].backGroundColor}
          subtitle={overlayContent[lastVisible].subtitle}
          text= {overlayContent[lastVisible].text}
          close={this.closeModal}
          />
        </Modal>

        <Row className="How--hr">
          <p>Ideologiq</p>
          <hr />
          <p>How</p>
        </Row>
        <Row className="Why--title">
          <span className="Why--title--letters">
            <LetterWrapper className="Why--title--letter" letter='H' delay={0.9}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='O' delay={0.7}></LetterWrapper>
            <LetterWrapper className="Why--title--letter" letter='W' delay={0.5}></LetterWrapper>
          </span>
        </Row>
        <Row className="How--firstDescription">
          <p>We function in a lot of roles – parents, Java engineers, stamp collectors, childhood friends, wine-lovers, dream-catchers, long skirt fans and anti-war protesters, flute amateurs, or secret botanists – a never-ending series of roles we all take and leave.</p>
        </Row>
        <Row className="How--secondDescription">
          <p>At the inter-sections of such roles, we cure the gaps, we make transitions possible and create the comon grounds for all to function comfortably.</p>
        </Row>

        <Row className='How--cardsRow'>
          {overlayContent.map((card, index) => 
            <Col xl={4} style={{marginTop: 20, padding: 0}} className='How--cardsCol'>
              <Card
                title={card.title}
                description={card.subtitle}
                onClick={() => this.openModal(index)}
                color={card.backGroundColor}
              />
            </Col>
          )}
        </Row>

        <Row>
          <InteractiveSections />
        </Row>
          <Row style={{height: 100}}>
          </Row>
          <Row>
          <Footer onClick={() => this.handleScroll()}/>
        </Row>
      </Container>
    )
  }
}

export default How
