import * as React from 'react'

// Import necessary components for React Google Maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import marker from '../../assets/logos/marker.png';
// Import custom styles to customize the style of Google Map
const styles = require('./GoogleMapStyles.json')

// Import custom icon for map marker
// You can use this if you need to support IE11 and lower.
// const mapMarker = require('./GoogleMapMarker.svg')

// Google Map component
const GoogleMapComponentWithMarker = withScriptjs(
  withGoogleMap(props => {
    return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={ props.loc === 'romania-bucharest-head-office' ? {
        lat: 44.4392822, 
        lng: 26.1071811 
      } : {
        lat: 37.868564, 
        lng: 23.7386258
      }}
      center={ props.loc === 'romania-bucharest-head-office' ? {
        lat: 44.4392822, 
        lng: 26.1071811 
      } : {
        lat: 37.868564, 
        lng: 23.7386258
      }}
      defaultOptions={{
        disableDefaultUI: true, // disable default map UI
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        scrollwheel: true, // allow scroll wheel
        styles: styles // change default map styles
      }}
    >
      <Marker
        icon={marker}
        onClick={() => props.markerClick()}
        key='MarkerRo'
        position={{lat: 44.4392822, lng: 26.1071811 }}>
      </Marker>
      <Marker
        onClick={() => props.markerClick()}
        icon={marker}
        key='MarkerGreece'
        position={{lat: 37.868564, lng: 23.7386258 }}>
      </Marker>
    </GoogleMap>
  )})
)

// Export Google Map component
export default GoogleMapComponentWithMarker