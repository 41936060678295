import React, { Component } from 'react'
import './style.scss';
import { Container, Row } from 'react-bootstrap';
import backArrow from '../../assets/logos/arrowLeft.svg';

class HowOverlay extends Component {

  render () {
    const {
      backGroundColor,
      title, 
      subtitle,
      text,
      close
    } = this.props;
    return (
      <Container fluid className="Overlay" style={{backgroundColor: backGroundColor || 'gray', minHeight: '100vh'}} onClick={() => close()}>
        <Row className="Overlay--arrow">
          <img src={backArrow} alt="audi Img"/>
        </Row>
        <Row className='Overlay--title'>
          <p>{title}</p>
        </Row>
        <Row className='Overlay--subtitle'>
          <p>{subtitle}</p>
        </Row>
          {text.map(t => <Row className='Overlay--content'>
            <p>{t}</p>
          </Row>)}
          <Row style={{height: 50}} />
      </Container>
    )
  }
}

export default HowOverlay
