import React, { Component } from 'react'
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import projects from './config';
import { CommonLoading } from 'react-loadingg';
import arrowLeft from '../../assets/logos/arrowLeft.svg';
import {browserHistory} from 'react-router';
import Footer from '../../components/footer';
const isMobile = window.innerWidth <= 500;

class ProjectDetails extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object 
    }

    state={
        videoLoaded: false
    }

    componentDidMount() {
        if (this.video) {
          this.video.addEventListener("loadeddata", () => {
            this.setState({videoLoaded: true})
            this.video.play()
          });
        }
      }
    
      componentWillUnmount() {
        if (this.video) {
          this.video.removeEventListener("loadeddata", () => {
          });
        }
      }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.params.index != this.props.index) {
            this.handleScroll()
        }
    }

    handleScroll = () => {
        setTimeout(() => {
            this.myRef.current.scrollIntoView({ behavior: 'smooth' })
        }, 10)
    }

    render () {     
    
        const {videoLoaded} = this.state;
        const {project, index} = this.props.params;

        return (
        <Container fluid className="ProjectDetails" ref={this.myRef}>
            {isMobile && <Row style={{height: 150}}>

            </Row>}
            {!videoLoaded && <CommonLoading color="#ffa500" />}
            <video 
            ref={ref => (this.video = ref)}
            autoPlay
            playsInline
            loop
            muted
            style={{
                opacity: this.state.videoLoaded ? 1 : 0,
                transition: "opacity, 2s ease-in-out"
            }}
            className='ProjectDetails--video'
            >
                <source src={projects[project][index].video} type='video/mp4' />
            </video>
            <div className="ProjectDetails--details">
                <img src={arrowLeft} alt='backArrow' className="ProjectDetails--details--back" onClick={() => browserHistory.push(projects[project][index].back)}/>
                <div className="ProjectDetails--details--title">
                    <p>{projects[project][index].title}</p>
                </div>
                <div className="ProjectDetails--details--description">
                    <p>Here at Ideologiq, we rely on strategic outsights to kick-off the ideation process for any project that we’re tackling. From there onwards, we’re delving into the depths of the issue that is to be addressed in order to reach that one meaningfully relevant insight upon which the final </p>                </div>
                <div className="ProjectDetails--details--navigate">
                    {index > 0 && <p 
                        onClick={() => {
                        browserHistory.push('/project/' + project + '/' + (parseInt(index) - 1).toString())
                        window.location.reload();
                        }}>PREV</p>}
                    {index < 5 && <p onClick={() => {
                        browserHistory.push('/project/' + project + '/' + (parseInt(index) + 1).toString())
                        window.location.reload();
                    }}>NEXT</p>}
                </div>
            </div>
            <Row className='ProjectDetails--footer'>
                <Footer onClick={() => this.handleScroll()}/>
            </Row>
        </Container>
        )
  }
}

export default ProjectDetails;
