import React, { Component } from 'react'
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import data from './config';
import ImageWithDescription from '../../components/imageWithDescription';
import LetterNavigation from '../../components/letterNavigation';
import {browserHistory} from 'react-router';
import Footer from '../../components/footer';
import InteractiveSections from '../../components/interactive';

class Letters extends Component {

    state= {
        letter: ''
    }

    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object 
    }

    componentDidMount() {
        const path = this.props.location.pathname;    
        let letter = '';
        if(path === '/virtual-reality-innovation-projects/')
            letter = 'i';
        else if(path === '/design-branding-projects/')
            letter = 'd';
        else if(path === '/engagement-social-media-projects/')
            letter = 'e';
        else if(path === '/SEO-CRO-UX-optimisation-projects/')
            letter = 'o';
        else if(path === '/loyalty-programs-projects/')
            letter = 'l';
        else if(path === '/mobile-apps-omnichannel-ecommerce-projects/')
            letter = 'o2';
        else if(path === '/mobile-games-VR-games-gamification-projects/')
            letter = 'g';
        this.setState({letter: letter}, this.handleScroll())
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.params.letter != this.props.letter)
            this.handleScroll()
    }

    handleScroll = () => {
        setTimeout(() => {
            this.myRef.current.scrollIntoView({ behavior: 'smooth' })
        }, 10)
    }
    render () {     
    
    const {letter} = this.state;
    return (
        letter && <Container fluid className="Letters" style={{backgroundColor: data[letter].color}} ref={this.myRef}>
          <Row className="Letters--presentation">
              <Col xl={4} className="Letters--leftCol">
                <p className='Letters--leftCol--logo'>IDEOLOGIQ</p>
                <img src={data[letter].letter} alt='letter' className='Letters--leftCol--bigLetter'/>
              </Col>
              <Col xl={8}>
                <Row className="Letters--ideals">
                    <p>Ideals</p>
                    <hr />
                    <p>Optimisation</p>
                </Row>
                <Row className="Letters--title">
                    <p>{data[letter].title}</p>
                </Row>
                <Row className="Letters--subTitle">
                    <p>{data[letter].subTitle}</p>
                </Row>
                <Row className="Letters--content">
                    {data[letter].firstDescriprion.map((paragraph, index) => <p key={`paragraph${index}`}>{paragraph}</p>)}
                </Row>
                <Row style={{marginRight: '10%'}}>
                    {data[letter].directives.map((directive, index) => 
                            <Col className="Letters--statistics" key={`directive${index}`}>
                                <Row className="Letters--statistics--numbers">
                                    <p>{directive.title}</p>
                                </Row>
                                <Row className="Letters--statistics--text">
                                    <p>{directive.description}</p>
                                </Row>
                            </Col>
                        )
                    }
                </Row>
              </Col>
          </Row>

          <Row className="Letters--secondRow"  style={{backgroundColor: data[letter].color}}>
            <Col xl={4} className="Letters--secondRow--leftCol">
                {data[letter].secondDescription.map((paragraph, index) => <p key={`paragraph2${index}`}>{paragraph}</p>)}
            </Col>
            <Col xs={12} xl={8} className="Letters--secondRow--rightCol">
                <ImageWithDescription 
                    title={data[letter].images[0].title}
                    subTitle={data[letter].images[0].subTitle}
                    img={data[letter].images[0].img}
                    onClick={() => browserHistory.push('/project/' + letter + '/0')}
                />
            </Col>
          </Row>

          <Row className="Letters--secondRow--rightCol">
            <ImageWithDescription 
                title={data[letter].images[1].title}
                subTitle={data[letter].images[1].subTitle}
                img={data[letter].images[1].img}
                onClick={() => browserHistory.push('/project/' + letter + '/1')}
            />
          </Row>

          <Row className="Letters--secondRow--rightCol">
            {data[letter].images.slice(2, 6).map((img, index) => 
                <Col xs={12} xl={3} style={{padding: 0}} key={`image${index}`}>
                    <ImageWithDescription 
                        className='imgWd'
                        title={img.title}
                        subTitle={img.subTitle}
                        img={img.img}
                        onClick={() => browserHistory.push('/project/' + letter + '/' + (index + 2))}
                    />
                </Col>
            )}
          </Row>

          <Row style={{marginTop: 20}}>
                <InteractiveSections letter={true} />
          </Row>

          <Row>
            <LetterNavigation selected={letter} />
          </Row>
          <Row>
            <Footer onClick={() => this.handleScroll()} />
          </Row>
      </Container>
    )
  }
}

export default Letters;
